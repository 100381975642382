import { graphql, PageProps, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout/Layout";
import Box from "../components/Box/Box";
import Seo from "../components/Seo";
import { Paragraph, Paragraph2, Title } from "../components/Styled/Text";
import LinkArrowBtn from "../components/LinkArrowBtn";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { PressQuery } from "../types/pages/press";
import { get } from "../constants/theme";
import GatsbyImageWithArtDirection from "../components/GatsbyImageWithArtDirection";

interface Props extends PageProps {
  data: PressQuery;
}

const Press = ({ data, location }: Props) => {
  const { height } = useWindowSize();

  return (
    <>
      <Seo
        title={data.translationsJson.pages.press.seo.title}
        description={data.translationsJson.pages.press.seo.description}
        keywords={data.translationsJson.pages.press.seo.keywords}
        pathname={location.pathname}
        metaImage={
          data.translationsJson.pages.press.seo.image.childImageSharp
            .gatsbyImageData
        }
      />

      <Layout pathname={location.pathname}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: height,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 10,
              color: "#ffff",
              userSelect: "none",
              fontFamily: "custom_42263",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                fontFamily: "custom_42266",
                fontSize: "21px",
                fontWeight: 400,
                color: get("palette.text.dark_gold"),
                marginBottom: "30px",
              }}
              as="span"
            >
              {data.translationsJson.pages.press.header.subtitle}
            </Box>
            {data.translationsJson.pages.press.header.title.map((t, i) => (
              <Title variant="bigHero" key={i} sx={{ color: "white" }}>
                {t}
              </Title>
            ))}

            <Paragraph2
              variant="hero"
              sx={{
                color: "#fff",
                opacity: 0.85,
                textAlign: ["center", "center", "center", "center"],
                maxWidth: [
                  "90%",
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                ],
              }}
            >
              {data.translationsJson.pages.press.header.description}
            </Paragraph2>
          </Box>

          <GatsbyImageWithArtDirection
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
            imgStyle={{ objectFit: "cover" }}
            desktopImage={data.headerDesktop.childImageSharp.gatsbyImageData}
            mobileImage={data.headerMobile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "repeat(1, 1fr)",
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
            ],
            gap: "200px",
            alignItems: "center",
            minHeight: height,
            width: "100%",
            padding: ["0 5%", "0 5%", "0 180px", "0 180px"],
            marginTop: "200px",
            marginBottom: "200px",
            "& > :nth-child(1n)": {
              flex: 1,
            },
          }}
        >
          {data.translationsJson.pages.press.container.items.map((node, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box>
                <Box
                  as={GatsbyImage}
                  image={node.image.childImageSharp.gatsbyImageData}
                  alt={node.imageAlt}
                />
                <Paragraph
                  sx={{
                    marginBottom: "50px",
                    textAlign: "justify",
                    alignItems: "start",
                  }}
                >
                  {node.description}
                </Paragraph>
              </Box>
              <LinkArrowBtn
                to={node.link.publicURL}
                title={data.translationsJson.pages.press.linktitle}
                as={Link}
                sx={{
                  marginBottom: "40px",
                  marginTop: "40px",
                }}
              />
            </Box>
          ))}
        </Box>
      </Layout>
    </>
  );
};

export default React.memo(Press);

export const pressQuery = graphql`
  query PressQuery {
    headerDesktop: file(
      relativeDirectory: { eq: "pages/press/header" }
      name: { eq: "desktop" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    headerMobile: file(
      relativeDirectory: { eq: "pages/press/header" }
      name: { eq: "mobile" }
    ) {
      id
      relativeDirectory
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    translationsJson {
      pages {
        press {
          linktitle
          container {
            items {
              imageAlt
              description
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              link {
                publicURL
              }
            }
          }
          header {
            description
            subtitle
            title
          }
          seo {
            description
            keywords
            title
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        address
        addressLink
        email
        phone
        siteUrl
      }
    }
  }
`;
